import React, { useEffect, useState } from 'react';
import { Upload, Button, Progress, Tooltip } from 'antd';
import { UploadOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { GetAWSPresignedUrl, getKey } from 'functions';
import axios from 'axios';

interface FileType {
    name: string,
    status: string,
    percent: number,
    uid: string
}

export default function AWSUpload(props: {
    onDelete?: (item: any) => void,
    maxCount?: number,
    filekey?: string,
    callback?: (response: any) => void,
    files?: any,
    placeholder?: string,
    accept?: string,

}) {
    const {
        onDelete,
        maxCount = 1,
        callback,
        files,
        placeholder,
        accept
    } = props;
    const [fileList, setFileList] = useState<any[]>([]);
    const [initialUpdate, setInitialUpdate] = useState(false)

    // console.log(maxCount)
    useEffect(() => {
        if (files) {
            const updatedFileList = files.map((item: any) => ({
                ...item, // Spread the item to include all its properties
                name: item.name, // Add the 'name' property based on 'fileName'
                // You can add other properties here if needed
            }));

            setFileList(updatedFileList);
            setInitialUpdate(false);
        }
    }, [files, initialUpdate]);

    const handleChange = async (info: { file: any, fileList: any[] }) => {
        let response
        if (info.file.status === "removed") {
            onDelete && onDelete(info.file)
            setFileList(info.fileList)
            return
        } else {
            response = await handleUpload(info.file, info.fileList)
        }
        callback && callback(response)
    };


    const handleUpload = async (file: any, fileList: any[]) => {
        const { name, uid } = file;
        const updatedFileList = [...fileList];
        const fileIndex = updatedFileList.findIndex((item) => item.uid === uid);

        const updateProgress = (percent: number) => {
            console.log(`Progress: ${percent}, Status: ${updatedFileList[fileIndex].status}`);
            updatedFileList[fileIndex].percent = percent;
            updatedFileList[fileIndex].status = "uploading";
            setFileList([...updatedFileList]);

            if (percent === 100) {
                // If progress is 100%, set status to "done" after a delay
                updatedFileList[fileIndex].status = "done";
                console.log(`Progress is 100%, Status: ${updatedFileList[fileIndex].status}`);
                setFileList([...updatedFileList]);
            }
        };


        updateProgress(0); // Initial progress

        const onProgress = (e: number) => {
            updateProgress(e)
        }

        const response = await UploadtoAWS(file, onProgress)
        console.log("response ---------->", response)
        if (!response) {
            updatedFileList[fileIndex].status = "error";
            setFileList([...updatedFileList]);
            return;
        }

        const result = {
            uid: uid,
            url: response.config.url,
            name: name,
        };

        console.log('result', result)
        return result;
    };

    const handleDownload = async () => {

    }

    const handleDelete = (item: FileType) => {
        console.log(item)
        onDelete && onDelete(item)
    }



    return (
        <>
            <Upload
                accept={accept}
                multiple={true}
                maxCount={maxCount}
                fileList={fileList}
                beforeUpload={() => { return false }}
                onChange={handleChange}
                onDownload={handleDownload}
                showUploadList={false}

            >
                {fileList.length < maxCount &&
                    <Button type='primary'
                        className='mb-3'
                        icon={<UploadOutlined />}>{placeholder ? placeholder : "Upload"}</Button>
                }
            </Upload>

            <div className="file-list">
                {fileList.length > 0 && fileList.map((item, index) => (
                    <div className="attachment-item" key={"attachment-item" + index}>
                        <div className="item-group">
                            <p><FileOutlined className='mr-2' /> {item.name}</p>
                            <Tooltip title="Delete">
                                <DeleteOutlined onClick={() => handleDelete(item)} className='icon-delete' />
                            </Tooltip>
                        </div>
                        {item.status === "uploading" &&
                            <Progress size="small" percent={Math.ceil(item.percent)} className={"item-progress"} />
                        }
                    </div>
                ))}
            </div>
        </>
    );
}

const addRandomStringToFileName = (fileName: string, n = 15, rename = false) => {
    const randomString = getKey(n);
    const extension = fileName.split('.').pop(); // Get the file extension
    let baseFileName = fileName.replace(`.${extension}`, ''); // Remove the extension
    if (rename) {
        baseFileName = "file"
    }
    const newFileName = `${baseFileName}_${randomString}.${extension}`;
    return newFileName;
};

export const UploadtoAWS = async (file: any, onProgress: any, rename = false) => {
    let fileName = addRandomStringToFileName(file.name);
    if (rename) {
        fileName = addRandomStringToFileName(file.name, 30, rename);
    }

    const presignedUrl = await GetAWSPresignedUrl(file);
    console.log('presignedUrl', presignedUrl)

    console.log('fileName :', fileName)

    const response = axios.put(presignedUrl, file, {
        headers: {
            'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percentCompleted);
        },
    });


    return response;
};
