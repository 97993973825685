import React, { useEffect, useState } from "react";
import {
    LinkedinFilled, FacebookFilled, InstagramFilled, TwitterSquareFilled, PlusOutlined
} from '@ant-design/icons';
import { Button, message, Spin } from "antd";
import { User } from "types";
import { UpdateProfile } from "api";
import { getProfile, getToken } from "functions";
import { HOST } from "consts";
import ImgCrop from 'antd-img-crop';
import { Upload, Modal } from 'antd';
import { Field } from "components/ui/Field";

export default function EditProfile() {

    return (
        <div className="edit-profile">
            <div className="head mb-5">
                <h3>Profile</h3>
            </div>
            <EditProfileForm />
        </div >
    )
}


const EditProfileForm = () => {

    const handleGetProfile = async () => {
        const response = await getProfile()
        console.log(response)
        setProfile(response)
    }

    const [profile, setProfile] = useState<User | null>(null)

    const handleUpdate = ({ name, value }: { name: string, value: any }) => {
        setProfile((prev: any) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSaveProfile = async () => {
        const response = await UpdateProfile(profile)
        console.log(response)
        if (response.status) {
            message.success("Profile updated!")
        }

    }

    console.log('profile', profile)

    useEffect(() => {
        handleGetProfile()
    }, []);

    return (
        <div className="profile-form">
            <Spin spinning={!profile} />
            {profile
                &&
                <>
                    <div className="form">
                        <div className="field-group">
                            <Field
                                label="First Name"
                                name="firstname"
                                handleUpdate={handleUpdate}
                                type="char"
                                object={profile}
                            />
                            <Field
                                label="Last Name"
                                name="lastname"
                                handleUpdate={handleUpdate}
                                type="char"
                                object={profile}
                            />
                        </div>

                        <Field
                            label="About me"
                            name="bio"
                            handleUpdate={handleUpdate}
                            type="text"
                            object={profile}
                        />

                        <Field
                            label="Email"
                            name="email"
                            disabled
                            type="char"
                            object={profile}
                            handleUpdate={handleUpdate}
                        />


                        <div className="title is-6 mt-6">Social Media</div>
                        <Field
                            label="LinkedIn"
                            name="linkedin"
                            handleUpdate={handleUpdate}
                            type="char"
                            object={profile}
                            prefix={<LinkedinFilled />}
                        />

                        <Field
                            label="Twitter"
                            name="twitter"
                            handleUpdate={handleUpdate}
                            type="char"
                            object={profile}
                            prefix={<TwitterSquareFilled />}
                        />

                        <Field
                            label="Facebook"
                            name="facebook"
                            handleUpdate={handleUpdate}
                            type="char"
                            object={profile}
                            prefix={<FacebookFilled />}
                        />

                        <Field
                            label="Instagram"
                            name="instagram"
                            handleUpdate={handleUpdate}
                            type="char"
                            object={profile}
                            prefix={<InstagramFilled />}
                        />

                        <div className="button-group mt-3">
                            <Button
                                type="primary"
                                onClick={handleSaveProfile}
                            >Save
                            </Button>
                        </div>
                    </div >
                    <EditAvatar user={profile} />
                </>
            }
        </div>
    )
}


const EditAvatar = (props: {
    user: User
}) => {

    const { user } = props
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<any[]>([
        {
            uid: '-1',
            name: 'avatar',
            status: 'done',
            url: user.avatar
        },
    ]);
    const endpoint = HOST + "/api/ProfileAvatar/"
    const headers = { 'Authorization': `Bearer ${getToken()}` }

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload avatar
            </div>
        </div>
    );

    const handleUpload = async ({ file, onSuccess }: any) => {
        const method = "POST"
        const formData = new FormData();
        formData.append('file', file);
        const body = formData
        const { status, data, error } = await fetch(endpoint, { method, body, headers })
            .then(r => r.json()).then(r => { return r }).catch(err => { console.error(err) });

        console.log(data)
        if (status) {
            setFileList([{
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: data.avatar,
            }]);
            onSuccess(null, file);
        } else {
            message.error('Failed to upload file');
            setFileList((prev: any) => [...prev, {
                uid: file.uid,
                name: file.name,
                status: 'error',
                response: data.message,
            }]);
            onSuccess(error, null);
        }
    };
    const handleRemove = async (file: any) => {
        // Implement your own custom remove logic here
        const method = "DELETE"
        const response = await fetch(endpoint, { method, headers })
            .then(r => r.json()).then(r => { return r }).catch(err => { console.error(err) });

        console.log(response)
        const newFileList = fileList.filter(f => f.uid !== file.uid);
        setFileList(newFileList);
    };

    return (
        <>
            <div className='edit-avatar' style={{ width: "100%" }}>
                <ImgCrop rotationSlider>
                    <Upload
                        customRequest={handleUpload}
                        listType="picture-circle"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onRemove={handleRemove}
                        onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                    >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                </ImgCrop>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="avatar"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </div>
        </>
    );
}

const getBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


