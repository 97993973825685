import { Button, Input, message, notification } from 'antd';
import { HTMLRenderer } from 'functions'
import React, { useEffect, useState } from 'react'
import AWSUpload from 'components/ui/AWSUpload';
import { QuestionViewProps } from '../QuestionView';
import { Answer, Question } from 'types';
import { API } from 'api';
import { COURSE } from 'consts';
import cookie from "react-cookies"

export default function AttachmentQuestionView(props: QuestionViewProps & {
    type: string
}) {
    const { data, mode, session, type, setAllowNext } = props;
    const [answer, setAnswer] = useState<Answer>([])
    const [fileList, setFileList] = useState<any[]>([]);
    const [uploading, setUploading] = useState(false);
    const publicUser = cookie.load("public_user")
    const [api, contextHolder] = notification.useNotification()

    // console.log('setAllowNext', setAllowNext)

    const updateAnswer = (attachmentType: string, value: any) => {
        console.log('attachmentType', attachmentType)
        setAnswer([{
            question: data,
            answer: value,
        }])
    }

    const handleConfirm = () => {
        if (mode === COURSE.ENROLL_MODE.ENROLL || mode === COURSE.ENROLL_MODE.PUBLIC) {
            handleSaveAnswer()
        } else {
            setAllowNext(true)
        }
    }

    const handleSaveAnswer = async () => {
        setUploading(true)
        const formData = new FormData()
        answer.forEach((item: any) => {
            formData.append('question', JSON.stringify(item.question));
            formData.append('answer', item.answer);
            formData.append('session', session);
            formData.append('type', type);
            formData.append('mode', mode);
            if (mode === COURSE.ENROLL_MODE.PUBLIC) {
                formData.append("user", publicUser)
            }
        });
        const response = await API.Course.UserAnswer.SAVE(formData, mode)
        console.log(response)
        if (response.status) {
            api.success({
                message: "Answer saved successfully.",
            })
            setAllowNext(true)
        }
        setAllowNext(true)
        setUploading(false)
    }

    // console.log(data.attachment_type, answer)

    useEffect(() => {
        console.log("data", data)
        if (data.user_answer.length) {
            setAllowNext(true)
            const url = data.user_answer[0].user_answer_attachment_url
            setAnswer([{
                question: data,
                answer: url
            }])

            // const parsedUrl = new URL(url); // Parsing URL
            // const pathname = parsedUrl.pathname; // Ambil path dari URL
            // pathname.split("/").pop();

            const getFileName = (url: string) => {
                const parsedUrl = new URL(url); // Parsing URL
                const pathname = parsedUrl.pathname; // Ambil path dari URL
                return pathname.split("/").pop(); // Ambil bagian terakhir (nama file)
            };

            const fileName = getFileName(url);

            setFileList([{
                uid: data.id,
                name: fileName,
                status: 'done',
                url: url,
            },])
        }

        if (mode !== COURSE.ENROLL_MODE.ENROLL) {
            setAllowNext(true)
        }

        return () => {
            setFileList([])
            setAnswer([])
        }

    }, [data])


    const handleDelete = (e: any) => {
        console.log(e)
        setFileList(prev => {
            return prev.filter(item => item.uid !== e.uid)
        })
        setAnswer((prev: any) => {
            return prev.filter((item: any) => item.answer !== e.url)
        })
    }

    const onUploadSuccess = (e: any) => {
        console.log(e)
        if (e.url) {
            updateAnswer(data.attachment_type, e.url)
            setFileList(prev => [...prev, e])
            message.success("Upload success!")
        }
    }

    console.log('answer', answer)
    return (
        <div className={data.response_type + "-view"}>
            {contextHolder}
            <div className="question">
                <div className="question-label">Question</div>
                <HTMLRenderer content={data.question} />
            </div>

            <div className="tip mt-4 mb-3">Upload {data.attachment_type}</div>

            <div className={"mb-4 answer-box " + data.response_type}>
                {data.attachment_type === "Video" ?
                    <div>
                        <Input value={answer.length > 0 ? answer[0].answer : ""} placeholder='Input url' onChange={(e) => updateAnswer("Video", e.target.value)} />

                        <p className='mt-2'>Allowed format : {allowedFormat(data)}</p>
                    </div> :

                    <div>
                        <AWSUpload
                            maxCount={1}
                            callback={onUploadSuccess}
                            onDelete={handleDelete}
                            accept={allowedFormat(data)}
                            files={fileList}
                        />
                        <p className='mt-2'>Allowed format : {allowedFormat(data)}</p>
                    </div>
                }
            </div>

            <Button type='primary'
                loading={uploading}
                disabled={answer.length < 1 || answer[0].answer === ""}
                onClick={handleConfirm}>Confirm</Button>
        </div>
    )
}

const allowedFormat = (question: Question) => {
    console.log(question)
    switch (question.attachment_type) {
        case "Document":
            return ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .csv, .txt";
        case "Image":
            return ".jpg, .jpeg, .png, .gif";
        case "Audio":
            return ".mp3, .wav";
        case "Video":
            return "Video Url (YouTube, Vimeo)";
        default:
            return "";
    }
}